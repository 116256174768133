import React, { useEffect } from 'react';
import Prism from 'prismjs';
import { marked } from "marked";
// import { PrismCode } from './PrismCode';

import '../styles/preview.css';

function MarkdownOutput(props) {
    // console.log('PROPS:', props);

    // Used for testing PrismCode component
    // const code = `
    // const foo = 'foo';
    // const bar = 'bar';
    // console.log(foo + bar);
    // `

    // Allows line breaks with return button
    marked.setOptions({
        breaks: true, 
        highlight: function (code) {
            return Prism.highlight(code, Prism.languages.javascript, 'javascript');
        }
    });

    // Inserts target="_blank" into href tags (required for Codepen links)
    const renderer = new marked.Renderer();
    renderer.link = function (href, title, text) {
        return `<a target='_blank' title='${title}' href='${href}'>${text}</a>`;
    };

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        addBordersToTables();
    });

    return (
        <>
        <div 
            id='preview' 
            dangerouslySetInnerHTML={{
                __html: marked(props.markdown, { renderer: renderer })
            }}
        ></div>
        {/* <PrismCode
            code={code}
            language="js"
            plugins={["line-numbers"]}
        /> */}
        </>
    );
}

function addBordersToTables() {
    let ths = document.getElementsByTagName('th');
    changeCSSProperties(ths);
    let tds = document.getElementsByTagName('td');
    changeCSSProperties(tds);
}

function changeCSSProperties(items) {
    for (let item of items) {
        item.style.border = '2px solid rgb(40,44,52)';
        item.style.padding = '3px';
    }
}

export default MarkdownOutput;
