import React from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

function MarkdownInput(props) {
  // console.log('PROPS:', props);
  
  const handleInput = event => {
    props.setMarkdown(event.target.value);
  };

  return (
    <div>
        <FloatingLabel controlId='editor' label='Input'>
            <Form.Control 
                as = 'textarea'
                onChange={ handleInput }
                placeholder = 'Input' 
                value = { props.markdown } 
                style = {{ height: '500px' }}
            />
        </FloatingLabel>
    </div>
  );
}

export default MarkdownInput;
