import React from 'react';

function Header(props) {
    const h1 = <h1>markdown prewiewer</h1>;
    const h2 = <h2>Try it!</h2>;
    
    // console.log('PROPS:', props);
  
  return (
    <header>
        { h1 }
        { h2 }
    </header>
  );
}

export default Header;
