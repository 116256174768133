import React from 'react';
import FontAwesome from 'react-fontawesome';

function Footer(props) {
  // console.log('PROPS:', props);
  
  return (
    <footer>
      Created by <a href='https://saintgeo23.github.io/' target={'_blank'} rel='noreferrer'>saintgeo23</a>.
      Powered by 
      <FontAwesome
        name='rocket'
        size='2x'
        spin
        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
      />
      <a href='https://unfinited.work/' target={'_blank'} rel='noreferrer'>Unfinited.work</a>
    </footer>
  );
}

export default Footer;